import {Container, Nav, Navbar, NavbarBrand, NavLink, Stack} from "react-bootstrap";
import logo from '../img/logo.svg'
import NavbarToggle from "react-bootstrap/NavbarToggle";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import {useTranslation} from "react-i18next";
import {LangSelector} from "./LangSelector";

function TopMenu() {
    const {t} = useTranslation();
    return <Navbar bg="light" expand="lg" sticky="top" >
        <Container>
            <NavbarBrand><a href="#"><img src={logo} alt="Fosize"/></a></NavbarBrand>
            <NavbarToggle aria-controls="basic-navbar-nav"/>
            <NavbarCollapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <NavLink href="#contacts">{t("menu_contacts")}</NavLink>
                    <NavLink href="#features">{t("menu_features")}</NavLink>
                    <NavLink href={t("user_manual_link")}>{t("menu_user_manual")}</NavLink>
                </Nav>
                <Navbar.Text>
                    <LangSelector/>
                </Navbar.Text>
            </NavbarCollapse>
        </Container>
    </Navbar>
}

export {TopMenu}