import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {supportedLanguages} from "./components/LangRouter";
import googlePlayEn from './img/google-play-badge.png';
import googlePlayFr from './img/google-play-badge-fr.png';
import googlePlayDe from './img/google-play-badge-de.png';
import googlePlayRu from './img/google-play-badge-ru.png';
import manualEng from './manual/GuideEngMin.pdf';
import manualRus from './manual/GuideRusMin.pdf';
import manualPol from './manual/GuidePLMin.pdf';
import manualDeu from './manual/GuideDeuMin.pdf';
import manualEsp from './manual/GuideEspMin.pdf';
import manualCh from './manual/GuideChnMin.pdf';
import manualFr from './manual/GuideFrMin.pdf';
import manualUk from './manual/GuideUkrMin.pdf';

const resources = {
    en: {
        translation: {
            menu_about: "About",
            menu_contacts: "Contacts",
            menu_features: "Features",
            menu_user_manual: "User manual",
            user_manual_link: manualEng,
            head_title: "Take your wood under control",
            head_text: "FOSIZE automates the measurement of roundwood. Measuring the volume and diameter by trunks and " +
                "in a stack. Easy export of results and a large selection of tools to work with. Flexible customization " +
                "for a specific user and manual data correction mode. You just take a photo. Everything else is done by FOSIZE.",
            first_card_text: "Volume measurement of roundwood by trunks and in a pile. Fast, Accurately, clearly.",
            second_card_text: "Large selection of customizable tools for the job. Individually, Simply, Affordable.",
            third_card_text: "Export of measurement results in any convenient way. E-mail, messengers, corporate services.",
            woods_measure_title: "Roundwood measurement",
            woods_measure_text: "Fosize guarantees high measurement accuracy. Machine learning and image recognition " +
                "algorithms do the main work. Manual adjustment of the height of the reference, the contour of the pile, " +
                "the circle of each trunk, adding and removing a trunk in the stack are available for the most " +
                "accurate adjustment.",
            tools_title: "Tools",
            tools_group_title: "Groups",
            tools_group_text: "This tool is useful for those who take a lot of photos and want to organize the storage " +
                "of their measurements. Just create groups with convenient names and easily find the measurements you need.",
            tools_filter_by_dim_title: "Trunks filtering by diameter",
            tools_filter_by_dim_text: "Quite often, you need to quickly understand how many trunks of a certain diameter " +
                "a pile contains. FoSize will help you. Take a photo or choose an already prepared measurement from the " +
                "group. Set the desired range of diameters and see the result. The application marks found trunks with " +
                "a given diameter and counts their number and volume.",
            tools_calculation_method_title: "Formula selection",
            tools_calculation_method_text1: "Currently Fosize supports follow calculation methods",

            calc_method_gost: "GOST 2708-75",
            calc_method_cilinder: "Cylindrical formula",
            calc_method_poland: "Poland standard",
            calc_method_doyle: "Doyle method",
            calc_method_jas: "JAS",
            calc_method_manitoba_cubic: "Manitoba Cubic Method",
            calc_method_nova_scotia_cubic_metre_log_scale: "Nova Scotia Cubic Metre Log Scale",
            calc_method_cubic_metre_method: "Cubic Metre Rule",
            calc_method_international_1_4_method: "International 1/4″ Log Rule",
            calc_method_roy_log_rule: "Roy Log Rule",
            calc_method_ontario_log_rule: "Ontario Log Rule",
            calc_method_scribner_decimal_c: "Scribner Decimal C",

            tools_calculation_method_text2: "Our team is working on expanding the list. If your company has specific " +
                "needs for timber calculation mechanics, please contact us and we will definitely find a solution ",
            tools_shipping_title: "Shipment",
            tools_shipping_text: "If you need to ship exact volume, just specify required volume and from which side " +
                "of the stack you need to ship. The algorithm will mark the trunks for shipment.",
            tools_export_title: "Exporting results",
            tools_export_text: "You can share the results of any measurement with colleagues and clients. Open the " +
                "measurement you are interested in in the FoSize app and select the \"Share\" option. Sending is " +
                "possible in any convenient way: by e-mail, messengers, social networks, cloud services. The sent " +
                "Excel file contains full information about the number of trunks in the pile, their diameters, " +
                "total volume, photo of the stack and GPS tag.",
            tools_settings_title: "Customizing the list of tree species and assortment",
            tools_settings_text: "If you plan to work with piles of certain species of trees, simply set in the " +
                "settings to \"show only these species and assortment\". Save time, enjoy your work.",
            contacts_title: "Contacts",
            we_on_facebook: "We on Facebook",
            contact_phone: "Phone: +48571522553",
            for_investors_title: "For clients and investors",
            for_investors_text1: "Your activity is related to logging, you already our client or you want to try it, but " +
                "you understand that your company needs an individual approach, development of certain technical " +
                "functionality, and its own tariff plans? We are open for cooperation. Write to us to discuss how Fosize " +
                "can be configured for your company and help you run your business.",
            for_investors_text2: "We invite investors to cooperate. Software for automating logging and timber trading " +
                "processes is perspective investment segment.",
            our_team_header: "Our team",
            vladimir_kelbas: "Vladimir Kelbas",
            taras_boyarski: "Taras Boyarski",
            pavel_boyarski: "Pavel Boyarski",
            taras_position: "Industry domain expert, co-founder",
            pavel_position: "CEO",
            vladimir_position: "CTO, co-founder",
            play_badge: googlePlayEn
        },
    },
    ru: {
        translation: {
            menu_about: "О нас",
            menu_contacts: "Контакты",
            menu_features: "Инструменты",
            menu_user_manual: "Руководство пользователя",
            user_manual_link: manualRus,
            head_title: "Возьми древесину под контроль",
            head_text: "FOSIZE автоматизирует измерение при работе с круглым лесом. Измерение объема и диаметра " +
                "древесины поствольно и в штабеле. Легкий экспорт результатов и большой выбор инструментов для работы. " +
                "Гибкая настройка под конкретного пользователя и режим ручной корректировки данных. Вы делаете фото. " +
                "Все остальное делает FOSIZE.",
            first_card_text: "Измерение объема круглого леса по стволам и в штабеле. Быстро, точно, наглядно.",
            second_card_text: "Большой выбор настраиваемых инструментов для работы. Индивидуально, просто, доступно.",
            third_card_text: "Экспорт результатов измерений любым удобным способом. Email, мессенджеры, корпоративные сервисы.",
            woods_measure_title: "Измерение леса",
            woods_measure_text: "Fosize обеспечивает высокую точность замера. Основную работу выполняет алгоритм " +
                "распознавания изображений и реализованный в виде сверточной нейронной сети. Для максимально точного измерения доступна ручная " +
                "корректировка распознанных объектов и контура штабеля. Также есть возможность добавления и удаления " +
                "обьектов.",
            tools_title: "Инструменты",
            tools_group_title: "Группы",
            tools_group_text: "Данный инструмент пригодится тем, кто делает много фотографий и хочет систематизировать " +
                "хранение сделанных замеров. Просто создайте рабочие группы с удобными названиями и легко находите " +
                "нужные замеры.",
            tools_filter_by_dim_title: "Выборка стволов по диаметру",
            tools_filter_by_dim_text: "Достаточно часто нужно быстро понять, сколько стволов определенного диаметра " +
                "содержит штабель. FoSize поможет вам. Сделайте фото или выберите уже готовый замер из рабочей группы. " +
                "Выставляем нужный диапазон диаметров и смотрим результат. Приложение подсвечивает найденные стволы с " +
                "заданным диаметром и считает их количество и кубатуру.",
            tools_calculation_method_title: "Выбор метода расчета",
            tools_calculation_method_text1: "FoSize учитывает региональную специфику и реализует различные методы расчета кубатуры." +
                "На данный момент поддерживаются",

            tools_calculation_method_text2: "Наша команда работает над расширением списка. Если у Вашей компании есть специфические " +
                "потребности по механике расчета древесины, напишите нам. Постараемся найти решение.",

            calc_method_gost: "ГОСТ 2708-75",
            calc_method_cilinder: "Формула обьема цилиндра",
            calc_method_poland: "Польский стандарт",
            calc_method_doyle: "Метод Дойла",
            calc_method_jas: "JAS",
            calc_method_manitoba_cubic: "Manitoba Cubic Method",
            calc_method_nova_scotia_cubic_metre_log_scale: "Nova Scotia Cubic Metre Log Scale",
            calc_method_cubic_metre_method: "Cubic Metre Rule",
            calc_method_international_1_4_method: "International 1/4″ Log Rule",
            calc_method_roy_log_rule: "Roy Log Rule",
            calc_method_ontario_log_rule: "Ontario Log Rule",
            calc_method_scribner_decimal_c: "Scribner Decimal C",

            tools_shipping_title: "Отгрузка",
            tools_shipping_text: "Вам необходимо отгрузить точную кубатуру? Просто укажите, какой объем и с какой стороны " +
                "штабеля нужно отгрузить. Алгоритм подсветит стволы для отгрузки.",
            tools_export_title: "Экспорт результатов",
            tools_export_text: "Результатами любого замера можно поделиться с коллегами и клиентами. Откройте " +
                "интересующий Вас замер в приложении FoSize и выберите опцию \"Поделиться\". Отправка возможна любым " +
                "удобным способом: на email, в мессенджеры, социальные сети, облачные диски. Отправленный Excel файл " +
                "содержит полную информацию о количестве стволов в штабеле, их диаметрах, суммарную кубатуру, фото " +
                "штабеля и GPS метку.",
            tools_settings_title: "Настройка списка пород деревьев и сортимента",
            tools_settings_text: "Если Вы планируете работать со штабелями определенных пород деревьев, просто " +
                "установите в настройках “показать только эти породы и сортимент”. Экономьте время, получайте " +
                "удовольствие от работы.",
            contacts_title: "Контакты",
            we_on_facebook: "Мы в Facebook",
            contact_phone: "Тел: +48571522553",
            for_investors_title: "Клиентам и инвесторам",
            for_investors_text1: "Ваша деятельность касается лесозаготовки, Вы уже наш клиент или хотите попробовать, " +
                "но понимаете, что Вашей компании требуется индивидуальный подход, разработка определенного технического " +
                "функционала, собственные тарифные планы? Мы открыты для переговоров. Напишите нам, чтобы обсудить, как " +
                "Fosize может быть сконфигурирован для Вашей компании и поможет Вам в ведении бизнеса.",
            for_investors_text2: "Приглашаем к сотрудничеству инвесторов. Программное обеспечение для автоматизации " +
                "процессов лесозаготовки и торговли лесом является интересным сегментом для инвестиций.",
            our_team_header: "Наша команда",
            vladimir_kelbas: "Владимир Кельбас",
            taras_boyarski: "Тарас Боярский",
            taras_position: "Эксперт в отраслевой области, сооснователь",
            pavel_boyarski: "Павел Боярский",
            pavel_position: "Исполнительный директор",
            vladimir_position: "Техничесий директор, сооснователь",
            play_badge: googlePlayRu
        },
    },
    fr: {
        translation: {
            menu_about: "Qui sommes nous",
            menu_contacts: "Contacts",
            menu_features: "Outis",
            menu_user_manual: "Manuel d'utilisation",
            user_manual_link: manualFr,
            head_title: "Prenez le bois sous contrôle",
            head_text: "FOSIZE automatise la mesure lorsque vous travaillez avec un bois rond. La mesure du volume et du " +
                "diamètre du bois s’effectue par tronc at en pile. Exportation facile des résultats et un grand choix " +
                "d'outils pour le travail. Réglage flexible et personnalisation pour chaque utilisateur et le mode de " +
                "réglage manuel des données. Vous prenez une photo. FOSIZE fait le reste.",
            first_card_text: "Mesurer les quantités de bois rond par tronc et en pile. Vite, précisément, clairement",
            second_card_text: "Un grand choix d'outils configurables pour le travail. Individuellement, facile, accessible.",
            third_card_text: "Exporter des mesures tout comme vous le souhaitez. E-mail, messengers, services d’entreprise.",
            woods_measure_title: "Mesure du bois ",
            woods_measure_text: "FoSize garantie une haute précision des mesure. Le travail essentiel est exécuté " +
                "par l’algorithme de reconnaissance d’images et d’apprentissage automatique. L’ajustement des objets " +
                "reconnus et du contour de la pile est disponible pour assurer un réglage aussi précis que possible. " +
                "Il est également possible d'ajouter et de supprimer des objets.",
            tools_title: "Outis",
            tools_group_title: "Groupes",
            tools_group_text: "Cet outil servira à ceux qui prennent beaucoup de photos et qui veulent systématiser " +
                "le stockage des mesures effectuées. Vous pouvez juste créer des groupes de travail avec les noms " +
                "pratiques et vous trouverez facilement les mesures nécessaires.",
            tools_filter_by_dim_title: "Sélection des troncs par diamètre",
            tools_filter_by_dim_text: "Assez souvent, il faut comprendre rapidement combien de troncs d'un certain " +
                "diamètre contiennent une pile. FoSize vous aidera. Prenez photo ou choisissez un mesure donné dans " +
                "le groupe de travail. Vous pouvez sélectionner la plage souhaitée des diamètres et regarder le " +
                "résultat. L’application met en évidence les troncs avec le diamètre donné et calcule leur quantité et leur cubature.",
            tools_calculation_method_title: "Sélection de la méthode de calcul",
            tools_calculation_method_text1: "FoSize prend en compte les spécificités régionales et implémente " +
                "différentes méthodes de calcul de cubature. Actuellement pris en charge",
            tools_calculation_method_text2: "Notre équipe travail sur l’extension de la liste. Si votre entreprise a " +
                "les nécessités spécifiques concernant la mécanique de calcule de bois vous pouvez nous contacter. " +
                "Nous essayerons de trouver la solution. ",

            calc_method_gost: "GOST 2708-75",
            calc_method_cilinder: "Formule du volume du cylindre",
            calc_method_poland: "Norme polonaise",
            calc_method_doyle: "Méthode de Doyle",
            calc_method_jas: "JAS",
            calc_method_manitoba_cubic: "Manitoba Cubic Method",
            calc_method_nova_scotia_cubic_metre_log_scale: "Nova Scotia Cubic Metre Log Scale",
            calc_method_cubic_metre_method: "Cubic Metre Rule",
            calc_method_international_1_4_method: "International 1/4″ Log Rule",
            calc_method_roy_log_rule: "Roy Log Rule",
            calc_method_ontario_log_rule: "Ontario Log Rule",
            calc_method_scribner_decimal_c: "Scribner Decimal C",

            tools_shipping_title: "Expédition",
            tools_shipping_text: "Avez-vous besoin d’expédier la cubature précise? Juste indiquez le volume et de " +
                "quel côté de la pile vous souhaitez expédier. L'algorithme mettra en évidence les troncs pour l'expédition.",
            tools_export_title: "Exportation des résultats",
            tools_export_text: "Les résultats de chaque mesure peuvent être partagés avec vos collègues et vos " +
                "clients. Ouvrez dans l’application le mesure qui vous intéresse et choisissez l’option «Partager». " +
                "L’envoie est possible par tous les moyens: par e-mail, par les messageries instantanées, les réseaux " +
                "sociaux, les services de cloud. Le fichier Excel envoyé contient des informations complètes sur " +
                "le nombre de troncs dans la pile, leurs diamètres, cubature totale, photo de la pile et les " +
                "coordonnées GPS.",
            tools_settings_title: "Configuration de la liste des essences et des assortiments du bois",
            tools_settings_text: "Si vous pensez à travailler avec des piles de certaines essences de bois, vous " +
                "pouvez juste choisir dans les paramètres \"afficher uniquement ces essences et assortiments\". " +
                "Gagnez du temps, profitez-vous de votre travail.",
            contacts_title: "Contacts",
            we_on_facebook: "Nous sommes sur Facebook",
            contact_phone: "Tél.: +48571522553",
            for_investors_title: "Aux clients et aux investisseurs",
            for_investors_text1: "Est-ce que votre activité concerne l'exploitation forestière, vous êtes déjà notre " +
                "client ou vous voulez essayer mais vous comprenez que votre entreprise nécessite une approche " +
                "individuelle, le développement de certaines fonctionnalités techniques, son propre forfait? Nous " +
                "sommes ouverts à la négociation. Contactez-nous pour discuter comment FoSize peut être configuré pour " +
                "votre entreprise et comment il peut vous aider dans votre activité commerciale. ",
            for_investors_text2: "Appelons les investisseurs à collaborer avec nous. Le logiciel d'automatisation " +
                "des processus d'exploitation forestière et de commerce du bois est un segment intéressant pour l'investissement.",
            our_team_header: "Notre équipe",
            vladimir_kelbas: "Vladimir Kelbas",
            taras_boyarski: "Taras Boyarski",
            pavel_boyarski: "Pavel Boyarski",
            taras_position: "Expert du domaine de l'industrie, cofondateur",
            pavel_position: "Directeur exécutif",
            vladimir_position: "Directeur technique, cofondateur",
            play_badge: googlePlayFr
        }
    },
    de: {
        translation: {
            menu_about: "Über uns",
            menu_contacts: "Kontakte",
            menu_features: "Werkzeuge",
            menu_user_manual: "Benutzerhandbuch",
            user_manual_link: manualDeu,
            head_title: "Übernehmen Sie die Kontrolle über das Holz",
            head_text: "FOSIZE automatisiert die Messung bei der Arbeit mit Rundholz. Messung des Volumens und des " +
                "Durchmessers von Holz am Stamm und in einem Stapel. Einfacher Export der Ergebnisse und eine große " +
                "Auswahl an Werkzeugen für die Arbeit. Flexible benutzerspezifische Konfiguration und manueller " +
                "Datenkorrekturmodus. Sie machen ein Foto. Alles andere macht FOSIZE.",
            first_card_text: "Messung des Rundholzvolumens an Stämmen und im Stapel. Schnell, präzise, visuell.",
            second_card_text: "Große Auswahl an anpassbaren Tools für die Arbeit. Individuell, einfach, günstig.",
            third_card_text: "Exportieren Sie Messergebnisse auf beliebige Weise. E-Mail, Instant Messenger, Unternehmensdienste.",
            woods_measure_title: "Waldmessung",
            woods_measure_text: "Fosize bietet eine hohe Messgenauigkeit. Die Hauptarbeit wird vom " +
                "Bilderkennungsalgorithmus geleistet und als Convolutional Neural Network implementiert. Für die " +
                "genaueste Messung steht eine manuelle Korrektur der erkannten Objekte und der Kontur des Stapels zur " +
                "Verfügung. Es ist auch möglich, Objekte hinzuzufügen und zu entfernen.",
            tools_title: "Werkzeuge",
            tools_group_title: "Gruppen",
            tools_group_text: "Dieses Tool ist nützlich für diejenigen, die viele Fotos machen und die Speicherung " +
                "der durchgeführten Messungen systematisieren möchten. Erstellen Sie einfach Arbeitsgruppen mit " +
                "einfachen Namen und finden Sie ganz einfach die benötigten Messungen.",
            tools_filter_by_dim_title: "Auswahl der Stämme nach Durchmesser",
            tools_filter_by_dim_text: "Oft müssen Sie schnell verstehen, wie viele Fässer eines bestimmten Durchmessers " +
                "der Stapel enthält. FoSize hilft Ihnen dabei. Machen Sie ein Foto oder wählen Sie eine vorgefertigte " +
                "Messung aus der Arbeitsgruppe aus. Stellen Sie den gewünschten Durchmesserbereich ein und sehen Sie das " +
                "Ergebnis. Die Anwendung hebt die gefundenen Fässer mit einem bestimmten Durchmesser hervor und zählt deren Anzahl und Hubraum.",
            tools_calculation_method_title: "Wahl der Berechnungsmethode",
            tools_calculation_method_text1: "FoSize berücksichtigt regionale Besonderheiten und implementiert " +
                "verschiedene Methoden zur Berechnung der Kubatur. Derzeit unterstützt",
            tools_calculation_method_text2: "Unser Team arbeitet daran, die Liste zu erweitern. Wenn Ihr Unternehmen " +
                "spezielle Anforderungen an Holzberechnungsmechanik hat, kontaktieren Sie uns bitte. Wir werden " +
                "versuchen, eine Lösung zu finden.",

            calc_method_gost: "GOST 2708-75",
            calc_method_cilinder: "Zylinder volumen formel",
            calc_method_poland: "Polnischer Standard",
            calc_method_doyle: "Doyle-Methode",
            calc_method_jas: "JAS",
            calc_method_manitoba_cubic: "Manitoba Cubic Method",
            calc_method_nova_scotia_cubic_metre_log_scale: "Nova Scotia Cubic Metre Log Scale",
            calc_method_cubic_metre_method: "Cubic Metre Rule",
            calc_method_international_1_4_method: "International 1/4″ Log Rule",
            calc_method_roy_log_rule: "Roy Log Rule",
            calc_method_ontario_log_rule: "Ontario Log Rule",
            calc_method_scribner_decimal_c: "Scribner Decimal C",

            tools_shipping_title: "Versand",
            tools_shipping_text: "Müssen Sie den genauen Hubraum versenden? Geben Sie einfach an, wie viel und von " +
                "welcher Seite des Stapels Sie versenden möchten. Der Algorithmus hebt die Trunks für den Versand hervor.",
            tools_export_title: "Ergebnisse exportieren",
            tools_export_text: "Die Ergebnisse jeder Messung können mit Kollegen und Kunden geteilt werden. Öffnen " +
                "Sie die Messung, an der Sie interessiert sind, in der FoSize-Anwendung und wählen Sie die Option " +
                "„Teilen“. Das Senden ist auf jede bequeme Weise möglich: per E-Mail, Instant Messenger, soziale " +
                "Netzwerke, Cloud-Laufwerke. Die gesendete Excel-Datei enthält vollständige Informationen über die " +
                "Anzahl der Stämme im Stapel, deren Durchmesser, Gesamtkubatur, Foto des Stapels und GPS-Tag.",
            tools_settings_title: "Einstellen der Liste der Holzarten und des Sortiments",
            tools_settings_text: "Wenn Sie planen, mit Stapeln bestimmter Baumarten zu arbeiten, stellen Sie einfach " +
                "die Einstellungen auf \"nur diese Arten und das Sortiment anzeigen\". Sparen Sie Zeit, genießen " +
                "Sie Ihre Arbeit.",
            contacts_title: "Kontakte",
            we_on_facebook: "Wir sind auf Facebook",
            contact_phone: "Telefon: +48571522553",
            for_investors_title: "Für Kunden und Investoren",
            for_investors_text1: "Ihre Tätigkeit bezieht sich auf die Protokollierung, sind Sie bereits unser Kunde " +
                "oder möchten Sie es ausprobieren, aber Sie verstehen, dass Ihr Unternehmen einen individuellen " +
                "Ansatz, die Entwicklung bestimmter technischer Funktionen und eigene Tarifpläne benötigt? Wir sind " +
                "offen für Verhandlungen. Schreiben Sie uns, um zu besprechen, wie Fosize für Ihr Unternehmen " +
                "konfiguriert werden kann und Ihnen hilft, Ihr Geschäft zu führen.",
            for_investors_text2: "Wir laden Investoren zur Zusammenarbeit ein. Software zur Automatisierung der " +
                "Prozesse des Holzeinschlags und Holzhandels ist ein interessantes Investitionssegment.",
            our_team_header: "Unser Team",
            vladimir_kelbas: "Wladimir Kelbas",
            taras_boyarski: "Taras Bojarski",
            taras_position: "Branchenexperte, mitgründer",
            pavel_boyarski: "Pavel Boyarski",
            pavel_position: "Geschäftsführer",
            vladimir_position: "Technischer Leiter, mitbegründer",
            play_badge: googlePlayDe
        }
    },
    es: {
        translation: {
            menu_about: "Conózcanos",
            menu_contacts: "Contactos",
            menu_features: "Herramientas",
            menu_user_manual: "Guía del usuario",
            user_manual_link: manualEsp,
            head_title: "Toma la madera bajo control",
            head_text: "FOSIZE automatiza la medición cuando se trabaja con madera redonda. Medición del volumen y del diámetro de la madera por pieza y en la pila. Fácil exportación de resultados y una gran selección de herramientas para el trabajo. Ajuste flexible para cado usuario y modo de ajuste manual de datos. Usted toma una foto. Todo el resto lo hace FOSIZE.",
            first_card_text: "Medición del volumen de madera redonda en troncos y en pilas. Rápido, preciso, claramente. ",
            second_card_text: "Gran selección de herramientas personalizables para el trabajo. Individual, fácil, accesible.",
            third_card_text: "La exportación de los resultados de medición es posible de cualquier manera conveniente. Correo electrónico, mensajeros instantáneos, servicios corporativos.",
            woods_measure_title: "Medición del bosque",
            woods_measure_text: "Fosize garantiza una alta precisión de medición. Un algoritmo de reconocimiento de imágenes, implementado en forma de una red neuronal, realiza el trabajo principal. El ajuste manual de los objetos reconocidos y del contorno de la pila está disponible para la medición más precisa posible. También existe la posibilidad de añadir y eliminar objetos.",
            tools_title: "Herramientas",
            tools_group_title: "Grupos",
            tools_group_text: "Esta herramienta es útil para aquellos que toman muchas fotos y quieren sistematizar el almacenamiento de las mediciones realizadas. Simplemente cree grupos de trabajo con nombres convenientes y encuentre fácilmente las mediciones que necesita.",
            tools_filter_by_dim_title: "Muestreo de troncos por diámetro",
            tools_filter_by_dim_text: "A menudo es necesario comprender rápidamente cuántos troncos de un cierto diámetro contiene una pila. FoSize le ayudará. Tome una foto o seleccione una medición ya preparada del grupo de trabajo. Seleccione el intervalo deseado de diámetros y vea el resultado. La aplicación resalta los troncos encontrados con un diámetro dado y cuenta su número y cubatura.",
            tools_calculation_method_title: "Selección del método de cálculo",
            tools_calculation_method_text1: "FoSize tiene en cuenta la especificidad regional e implementa varios métodos para calcular la cubatura. Por el momento son compatibles",
            tools_calculation_method_text2: "Nuestro equipo está trabajando para ampliar la lista. Si su empresa tiene necesidades específicas en el método de cálculo de madera, escríbenos. Intentaremos encontrar una solución.",

            calc_method_gost: "GOST 2708-75",
            calc_method_cilinder: "Fórmula del volumen del cilindro",
            calc_method_poland: "Estándar polaco",
            calc_method_doyle: "Método de Doyle",
            calc_method_jas: "JAS",
            calc_method_manitoba_cubic: "Manitoba Cubic Method",
            calc_method_nova_scotia_cubic_metre_log_scale: "Nova Scotia Cubic Metre Log Scale",
            calc_method_cubic_metre_method: "Cubic Metre Rule",
            calc_method_international_1_4_method: "International 1/4″ Log Rule",
            calc_method_roy_log_rule: "Roy Log Rule",
            calc_method_ontario_log_rule: "Ontario Log Rule",
            calc_method_scribner_decimal_c: "Scribner Decimal C",

            tools_shipping_title: "Envío",
            tools_shipping_text: "¿Necesita enviar la cubatura exacta? Simplemente especifique qué volumen y desde qué lado de la pila se debe enviar. El algoritmo resaltará los troncos para el envío.",
            tools_export_title: "Exportar resultados",
            tools_export_text: "Los resultados de cualquier medición se pueden compartir con colegas y clientes. Abra la medición que le interesa en la aplicación FoSize y seleccione la opción \"Compartir\". El envío es posible de cualquier manera conveniente: por correo electrónico, mensajeros instantáneos, redes sociales, discos en la nube. El archivo enviado por Excel contiene información completa sobre el número de troncos en la pila, sus diámetros, la cubatura total, la foto de la pila y la marca GPS.",
            tools_settings_title: "Configurar la lista de especies y de surtidos de madera",
            tools_settings_text: "Si Usted planea trabajar con pilas de ciertas especies, simplemente configure \"Mostrar solo estas especies y surtido\" en la configuración. Ahorre tiempo, disfruta de su trabajo.",
            contacts_title: "Contactos",
            we_on_facebook: "Estamos en Facebook",
            contact_phone: "Tel.: +48571522553",
            for_investors_title: "Para clientes e inversores",
            for_investors_text1: "¿Su actividad se refiere a la industria maderera, ya es nuestro cliente o quiere probarlo, pero entiende que su empresa necesita un trato especial, el desarrollo de una cierta funcionalidad técnica, sus propios planes de tarifas? Estamos abiertos a la negociación. Contáctenos para discutir cómo se puede configurar Fosize para su empresa y ayudarlo a administrar su negocio.",
            for_investors_text2: "Invitamos los inversores a la cooperación. El software para automatizar los procesos de industria maderera y comercio forestal es un segmento interesante para la inversión.",
            our_team_header: "Nuestro equipo",
            vladimir_kelbas: "Vladimir Kelbas",
            vladimir_position: "Director técnico, cofundador",
            taras_boyarski: "Taras Boyarski",
            taras_position: "Experto en la industria, cofundador",
            pavel_boyarski: "Pavel Boyarski",
            pavel_position: "Director ejecutivo"
        }
    },
    pl:{
        translation:{
            menu_about: "O nas",
            menu_contacts: "Skontaktuj się z nami",
            menu_features: "Narzędzia",
            menu_user_manual: "Podręcznik użytkownika",
            user_manual_link: manualPol,
            head_title: "Przejmij kontrolę nad drewnem",
            head_text: "FOSIZE automatyzuje pomiar podczas pracy z okrągłym drewnem. Pomiar objętości i średnicy drewna w pniu i stosie. Łatwy eksport wyników i duży wybór narzędzi do pracy. Elastyczne dostosowanie do konkretnego użytkownika i tryb ręcznej regulacji danych. Robisz zdjęcie. FOSIZE robi resztę.",
            first_card_text: "Pomiar objętości drewna okrągłego według pnia i w stosie. Szybko, dokładnie, przejrzyście.",
            second_card_text: "Duży wybór dostosowanych narzędzi do pracy. Spersonalizowane, proste, dostępne.",
            third_card_text: "Eksportuj wyniki pomiarów w dowolny wygodny sposób. E-mail, komunikatory, usługi korporacyjne.",
            woods_measure_title: "Pomiar lasu",
            woods_measure_text: "Fsize zapewnia wysoką dokładność pomiaru. Główna praca jest wykonywana przez algorytm rozpoznawania obrazu i zrealizowania w postaci splotowej sieci neuronowej. Aby uzyskać jak najdokładniejszy pomiar, dostępna jest ręczna korekta rozpoznanych obiektów i konturu stosu. Istnieje również możliwość dodawania i usuwania obiektów.",
            tools_title: "Narzędzia",
            tools_group_title: "Grupy",
            tools_group_text: "To narzędzie przydaje się tym, którzy robią dużo zdjęć i chcą usystematyzować przechowywanie wykonanych pomiarów. Po prostu utwórz grupy robocze z wygodnymi nazwami i łatwo znajdź potrzebne pomiary.",
            tools_filter_by_dim_title: "Wybór pni według średnicy",
            tools_filter_by_dim_text: "Dość często trzeba szybko zrozumieć, ile pni o określonej średnicy zawiera stos. FoSize ci pomoże. Zrób zdjęcie lub wybierz gotowy pomiar z grupy roboczej. Ustawiamy żądany zakres średnic i patrzymy na wynik. Aplikacja podświetla znalezione pnie o określonej średnicy i zlicza ich liczbę i kubaturę.",
            tools_calculation_method_title: "Wybór metody obliczeniowej",
            tools_calculation_method_text1: "FoSize uwzględnia specyfikę regionalną i wdraża różne metody obliczania kubatury. W tej chwili obsługiwane są",
            tools_calculation_method_text2: "Nasz zespół pracuje nad rozszerzeniem listy. Jeśli Twoja firma ma specyficzne potrzeby w zakresie mechaniki obliczania drewna, skontaktuj się  nas. Spróbujemy znaleźć rozwiązanie.",

            calc_method_gost: "GOST 2708-75",
            calc_method_cilinder: "Wzór na objętość walca",
            calc_method_poland: "Polski Standard",
            calc_method_doyle: "Metoda Doyle’a",
            calc_method_jas: "JAS",
            calc_method_manitoba_cubic: "Manitoba Cubic Method",
            calc_method_nova_scotia_cubic_metre_log_scale: "Nova Scotia Cubic Metre Log Scale",
            calc_method_cubic_metre_method: "Cubic Metre Rule",
            calc_method_international_1_4_method: "International 1/4″ Log Rule",
            calc_method_roy_log_rule: "Roy Log Rule",
            calc_method_ontario_log_rule: "Ontario Log Rule",
            calc_method_scribner_decimal_c: "Scribner Decimal C",

            tools_shipping_title: "Załadunek",
            tools_shipping_text: "Potrzebujesz załadować dokładną kubaturę? Po prostu określ objętość i po której stronie stosu ma być załadowany. Algorytm podświetli pni do załadunku.",
            tools_export_title: "Eksportowanie wyników",
            tools_export_text: "Wyniki dowolnego pomiaru można udostępniać współpracownikom i klientom. Otwórz pomiar, który Cię interesuje, w aplikacji FoSize i wybierz opcję \"Udostępnij\". Wysyłanie jest możliwe w dowolny wygodny sposób: na e-mail, messengerze, sieci społecznościowe, cloud drivers. Wysłany plik Excel zawiera pełne informacje o liczbie pni w stosie, ich średnicach, łącznej kubaturze, zdjęciu stosu i etykiecie GPS.",
            tools_settings_title: "Ustawienie listy gatunków drzew i sortymentu",
            tools_settings_text: "Jeśli planujesz pracować ze stosami określonych gatunków drzew, po prostu zaznacz w  ustawieniach \"pokaż tylko te gatunki i sortyment\". Oszczędzaj czas i ciesz się swoją pracą.",
            contacts_title: "Kontakt",
            we_on_facebook: "Nasza strona Facebook",
            contact_phone: "tel.: +48571522553",
            for_investors_title: "Klientom i inwestorom",
            for_investors_text1: "Twoja działalność dotyczy rejestrowania, jesteś już naszym klientem lub chcesz spróbować, ale rozumiesz, że Twoja firma wymaga indywidualnego podejścia, opracowania określonej funkcjonalności technicznej, własnych planów taryfowych? Jesteśmy otwarci na negocjacje. Napisz do nas, aby omówić, w jaki sposób Fosize można skonfigurować dla Twojej firmy i pomóc w prowadzeniu firmy.",
            for_investors_text2: "Inwestorzy są mile widziani. Oprogramowanie do automatyzacji procesów związanych z pozyskiwaniem i handlem drewnem to ciekawy segment do inwestycji.",
            our_team_header: "Nasz zespół",
            vladimir_kelbas: "Vladimir Kelbas",
            vladimir_position: "Dyrektor techniczny, współzałożyciel",
            taras_boyarski: "Taras Boyarski",
            taras_position: "Ekspert domeny branżowej, współzałożyciel",
            pavel_boyarski: "Pavel Boyarski",
            pavel_position: "Dyrektor wykonawczy"
        }
    },
    zh:{
      translation:{

          menu_about: "关于我们",
          menu_contacts: "联系人",
          menu_features: "工具",
          menu_user_manual: "用戶說明書",
          user_manual_link: manualCh,
          head_title: "监管木材",
          head_text: "FOSIZE自动测量圆木。能测量出树干和成堆木材的体积和直径。快速导出测量结果且服务工具选择很大。针对特定用户可以灵活定制和数据手动校正模式。 您只要拍张照片。 其它一切都由FOSIZE完成。",
          first_card_text: "测量树干和成堆木材的体积. 快速，精准，直观。",
          second_card_text: "工作设置工具选择很大。独特，简单，便捷。",
          third_card_text: "能以任何方便的方式导出测量结果。 电子邮件，聊天软件，企业服务器。",
          woods_measure_title: "木材测量",
          woods_measure_text: "Fosize确保高测量精度。 主要工作由图像识别算法执行，并以人工智能网络的形式实现。 为了更精确的测量可以手动校正识别的对象和成堆木材的轮廓。 也可以添加和删除测量对象。",
          tools_title: "工具",
          tools_group_title: "分组",
          tools_group_text: "这个工具是为那些拍出了大量的照片，希望系统化保存测量数据。 只需创建带有合适名称的工作分组，即可轻松找到必要的测量值。",
          tools_filter_by_dim_title: "按直径选取木材",
          tools_filter_by_dim_text: "很多时候，需要快速知道成堆的木材中有多少指定直径的树干。这方面 FoSize 可以帮助您。 对它进行拍照或从工作分组中选出现成的测量值。 我们设置所需的直径范围就能查看到结果。 该应用程序清晰显示出找到的指定直径的树干，并计算它们的数量和体积。",
          tools_calculation_method_title: "选择计算方法",
          tools_calculation_method_text1: "FoSize根据区域的具体情况，目前支持各种计算立方容量的方法。 ",
          tools_calculation_method_text2: "我们的团队正在努力扩大计算方法。 如果贵公司对木材计算有特殊要求，请写信给我们。 我们将努力找到解决方案。",

          calc_method_gost: "ГОСТ 2708-75",
          calc_method_cilinder: "圆柱体积公式",
          calc_method_poland: "波兰标准",
          calc_method_doyle: "道尔方法",
          calc_method_jas: "JAS",
          calc_method_manitoba_cubic: "Manitoba Cubic Method",
          calc_method_nova_scotia_cubic_metre_log_scale: "Nova Scotia Cubic Metre Log Scale",
          calc_method_cubic_metre_method: "Cubic Metre Rule",
          calc_method_international_1_4_method: "International 1/4″ Log Rule",
          calc_method_roy_log_rule: "Roy Log Rule",
          calc_method_ontario_log_rule: "Ontario Log Rule",
          calc_method_scribner_decimal_c: "Scribner Decimal C",

          tools_shipping_title: "装运",
          tools_shipping_text: "您需要装运确切的立方量吗？ 只需指定需要装运多少m3以及从木堆的哪一侧装。算法能显示出装运的树干。",
          tools_export_title: "输出结果",
          tools_export_text: "任何测量的结果都可以与同事和客户共享。 只需在FoSize应用程序中打开您需要的测量值，然后选择\"共享\"选项。 可以通过任何方便的方式发送：电子邮件，聊天软件，社交软件，云盘。 发送的Excel文件包含有关木堆中圆木数量，直径，总立方量，及木堆照片和GPS定位的完整信息。",
          tools_settings_title: "设置树种和种类清单",
          tools_settings_text: "如果您计划测量某种树种的堆栈，只需在设置中设置为\"仅显示这些树种和种类\"。节约时间，并享受工作。",
          contacts_title: "联系人",
          we_on_facebook: "我们在Facebook上",
          contact_phone: "电话：+48571522553",
          for_investors_title: "给客户和投资者",
          for_investors_text1: "您从事木材采运，您已经是我们的客户或者您想尝试一下，但您知道您的公司需要单独的方法，某些技术功能的开发，自己的计划？ 我们开放洽谈。请写信给我们，讨论如何为您的公司配置Fosize，并帮助您做生意。",
          for_investors_text2: "我们邀请投资者合作. 木材采运和木材贸易过程自动化软件是一个前景的投资领域。",
          our_team_header: "我们的团队",
          vladimir_kelbas: "Vladimir Kelbas",
          vladimir_position: "技术总监，联合创始人",
          taras_boyarski: "Taras Boyarski",
          taras_position: "行业专家，联合创始人",
          pavel_boyarski: "Pavel Boyarski",
          pavel_position: "执行经理"

      }
    },
    uk: {
        translation: {
            menu_about: "Про нас",
            menu_contacts: "Контакти",
            menu_features: "Інструменти",
            menu_user_manual: "Посібник користувача",
            user_manual_link: manualUk,
            head_title: "Візьміть деревину під контроль",
            head_text: "FOSIZE автоматизує процеси вимірювання під час роботи з круглим лісом. Вимірювання об'єму та діаметру деревини постовбурно і в штабелі. Простий експорт результатів та широкий асортимент інструментів для роботи. Гнучке налаштування під потреби користувача та режим ручного коригування даних. Ви робите фото — решту робить FOSIZE.",
            first_card_text: "Вимірювання обсягу круглого лісу по стовбурах та у штабелі. Швидко, точно та наочно.",
            second_card_text: "Великий вибір інструментів, що налаштовуються для роботи. Персоналізовано, легко та доступно.",
            third_card_text: "Експорт результатів у зручний для вас спосіб. Електронна пошта, месенджери та корпоративні послуги.",
            woods_measure_title: "Вимірювання лісу",
            woods_measure_text: "FoSize забезпечує високу точність вимірювання. Основну роботу виконує алгоритм розпізнавання зображень, що реалізований у формі  згорткової нейронної мережі. Для максимально точного вимірювання доступне ручне коригування розпізнаних об'єктів та контуру штабеля. Також існує можливість додавання та видалення об'єктів.",
            tools_title: "Інструменти",
            tools_group_title: "Групи",
            tools_group_text: "Цей інструмент стане у нагоді тим, хто робить багато фотофіксацій і хоче систематизувати зберігання вимірів. Просто створіть робочі групи зі зручними назвами та легко знаходьте потрібні дані.",
            tools_filter_by_dim_title: "Вибірка стовбурів за діаметром",
            tools_filter_by_dim_text: "Досить часто необхідно швидко визначити скільки стовбурів певного діаметра містить штабель. FoSize допоможе зробити це менше, наж за хвилину! Зробіть фото чи оберіть готовий замір з робочої групи. Виставте потрібний діапазон діаметрів та отримайте результат. Додаток підсвітить знайдені стовбури із заданим діаметром і одразу розрахує їхню кількість та кубатуру.",
            tools_calculation_method_title: "Вибір методу розрахунку",
            tools_calculation_method_text1: "FoSize враховує регіональну специфіку та застосовує найпоширеніші методи розрахунку. Наразі підтримуються:",
            tools_calculation_method_text2: "Наша команда невпинно працює над доповненням методів вимірювання. Якщо ваша компанія має специфічні вимоги щодо методики розрахунку — напишіть нам. Ми докладемо зусиль та знайдемо рішення.",

            calc_method_gost: "ГОСТ 2708-75",
            calc_method_cilinder: "Формула об'єму циліндра",
            calc_method_poland: "Польський стандарт",
            calc_method_doyle: "Метод Дойла",
            calc_method_jas: "JAS",
            calc_method_manitoba_cubic: "Manitoba Cubic Method",
            calc_method_nova_scotia_cubic_metre_log_scale: "Nova Scotia Cubic Metre Log Scale",
            calc_method_cubic_metre_method: "Cubic Metre Rule",
            calc_method_international_1_4_method: "International 1/4″ Log Rule",
            calc_method_roy_log_rule: "Roy Log Rule",
            calc_method_ontario_log_rule: "Ontario Log Rule",
            calc_method_scribner_decimal_c: "Scribner Decimal C",

            tools_shipping_title: "Відвантаження",
            tools_shipping_text: "Вам необхідно відвантажити точну кубатуру? Просто вкажіть, який об’єм та з якого боку штабеля підлягає відвантаженню. Додаток підсвітить відповідну кількість стовбурів.",
            tools_export_title: "Експорт результатів",
            tools_export_text: "Результатами будь-якого заміру можна легко поділитися з колегами та клієнтами. Відкрийте потрібний замір у додатку FoSize і виберіть опцію \"Поділитися\". Відправлення можливе у будь-який зручний для вас спосіб: на електронну пошту, у месенджери, соціальні мережі, на хмарні диски. Надісланий Excel файл містить повну інформацію щодо кількості стовбурів у штабелі, їхніх діаметрів, сумарної кубатури, а також фото штабеля та GPS мітку.",
            tools_settings_title: "Налаштування списку порід деревини та сортименту",
            tools_settings_text: "Якщо ви плануєте працювати зі штабелями певних порід деревини, просто виберіть у налаштуваннях \"Показати тільки ці породи та сортимент\". Заощаджуйте свій час та отримуйте задоволення від роботи!",
            contacts_title: "Контакти",
            we_on_facebook: "Ми у Facebook",
            contact_phone: "Тел. +48571522553",
            for_investors_title: "Клієнтам та інвесторам",
            for_investors_text1: "Ви працюєте у сфері лісозаготівлі? Можливо, ви вже наш клієнт, чи хочете спробувати, але Вашій компанії потрібен індивідуальний підхід, розробка певного технічного функціоналу, власні тарифні плани? Ми відкриті до перемовин! Напишіть нам, і ми запропонуємо варіанти персоналізованих налаштувань та розробимо індивідуальні рішення для вашої компанії чи бізнесу.",
            for_investors_text2: "Також будемо раді пропозиціям щодо співпраці від інвесторів. Сучасне програмне забезпечення для автоматизації процесів лісозаготівлі та торгівлі лісом є перспективним сегментом для інвестицій.",
            our_team_header: "Наша команда",
            vladimir_kelbas: "Володимир Кельбас",
            taras_boyarski: "Тарас Боярський",
            taras_position: "Експерт галузі, співзасновник",
            vladimir_position: "Технічний директор, співзасновник",
            pavel_boyarski: "Павел Боярский",
            pavel_position: "Виконавчий директор",
        }
    }
};

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        supportedLngs: supportedLanguages, //["en", "ru"/*, "uk"*/],
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;