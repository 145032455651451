import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function Footer() {
    const {t} = useTranslation();
    const phone = t("contact_phone");
    return <footer>
        <Container>
            <Row>
                <Col sm>
                    <h4 id="contacts">{t("contacts_title")}</h4>
                    <p>
                        Email: <a href="mailto:contact@fosize.com">contact@fosize.com</a>
                    </p>
                    <p>
                        <a href="https://www.facebook.com/profile.php?id=100082832157472"
                           target="_blank">{t("we_on_facebook")}</a>
                    </p>
                    <p>
                        <a href="https://www.youtube.com/channel/UCHXQJUAm_bZyY3_tmg2ORMg" target="_blank">
                            YouTube
                        </a>
                    </p>
                    {phone.length > 0 && <p>{phone}</p>}
                    <p>WatsApp: +48571522553</p>
                </Col>
                <Col sm>
                    <p>© 2024 Fosize PSA</p>
                </Col>
            </Row>
        </Container>
    </footer>
}

export {Footer}