import {Col, Container, Image, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import measureImg from "../img/measure.jpg";
import filterImg from "../img/filter-ru.jpg";
import shippingImg from "../img/shipping-ru.jpg";
import {useCallback, useState} from "react";
import ImageViewer from 'react-simple-image-viewer';
import {Team} from "./Team";

function MainContent() {
    const {t} = useTranslation();
    const images = [measureImg, filterImg, shippingImg];
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const openImageViewer = useCallback((imageIndex) => {
        setCurrentImage(imageIndex);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return <Container className="mainContentContainer">
        <article>
            <h3>{t("woods_measure_title")}</h3>
            <Row>
                <Col sm>
                    {t("woods_measure_text")}
                </Col>
                <Col sm><img onClick={() => openImageViewer(0)} src={measureImg} alt=""/></Col>
            </Row>
        </article>
        <h3 id="features">{t("tools_title")}</h3>
        <article>
            <h4>{t("tools_group_title")}</h4>
            <p>{t("tools_group_text")}</p>
        </article>
        <article>
            <h4>{t("tools_filter_by_dim_title")}</h4>
            <Row>
                <Col sm>
                    <img onClick={() => openImageViewer(1)} src={filterImg} alt="filter"/>
                </Col>
                <Col sm>
                    {t("tools_filter_by_dim_text")}</Col>
            </Row>
        </article>
        <article>
            <h4>{t("tools_calculation_method_title")}</h4>
            <p>{t("tools_calculation_method_text1")}</p>
            <ul>
                <li>{t("calc_method_gost")}</li>
                <li>{t("calc_method_cilinder")}</li>
                <li>{t("calc_method_poland")}</li>
                <li>{t("calc_method_doyle")}</li>
                <li>{t("calc_method_jas")}</li>
                <li>{t("calc_method_manitoba_cubic")}</li>
                <li>{t("calc_method_nova_scotia_cubic_metre_log_scale")}</li>
                <li>{t("calc_method_cubic_metre_method")}</li>
                <li>{t("calc_method_international_1_4_method")}</li>
                <li>{t("calc_method_roy_log_rule")}</li>
                <li>{t("calc_method_ontario_log_rule")}</li>
                <li>{t("calc_method_scribner_decimal_c")}</li>
            </ul>
            <p>
                {t("tools_calculation_method_text2")}
            </p>
        </article>
        <article>
            <h4>{t("tools_shipping_title")}</h4>
            <Row>
                <Col sm>
                    {t("tools_shipping_text")}
                </Col>
                <Col sm>
                    <img onClick={() => openImageViewer(2)} src={shippingImg} alt="shipping"/>
                </Col>
            </Row>
        </article>
        <article>
            <h4>{t("tools_export_title")}</h4>
            <p>{t("tools_export_text")}</p>
        </article>
        <article>
            <h4>{t("tools_settings_title")}</h4>
            <p>{t("tools_settings_text")}</p>
        </article>
        <article>
            <h4>{t("for_investors_title")}</h4>
            <p>{t("for_investors_text1")}</p>
            <p>{t("for_investors_text2")}</p>
        </article>
        <Team/>

        {isViewerOpen && (
            <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
                backgroundStyle={{zIndex: 2000}}
            />
        )}
    </Container>
}

export {MainContent}