import {Card, CardGroup, Col, Row} from "react-bootstrap";
import meterImg from '../img/meter.svg';
import toolsImg from '../img/tools.svg';
import exportImg from '../img/export.svg';
import {useTranslation} from "react-i18next";


function MainBlocksRow() {
    const {t} = useTranslation();
    return <CardGroup>
        <Card>

            {/*<Card.Img src={meterImg} />*/}

            <Card.Body>
                <div style={{textAlign: "center", paddingBottom:10}}>
                    <img src={meterImg} width={80}/>
                </div>
                <Card.Text>
                    {t("first_card_text")}
                </Card.Text>
            </Card.Body>
        </Card>
        <Card>
            <Card.Body>
                <div style={{textAlign: "center", paddingBottom:10}}>
                    <img src={toolsImg} width={80}/>
                </div>
                <Card.Text>
                    {t("second_card_text")}
                </Card.Text>
            </Card.Body>
        </Card>
        <Card>
            <Card.Body>
                <div style={{textAlign: "center", paddingBottom:10}}>
                    <img src={exportImg} width={80}/>
                </div>
                <Card.Text>
                    {t("third_card_text")}
                </Card.Text>
            </Card.Body>
        </Card>

    </CardGroup>
    /*</Row>*/
}

export {MainBlocksRow}