import {Stack} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function LangSelector() {
    const {i18n} = useTranslation();

    return <Stack direction="horizontal">
        {
            i18n.options.supportedLngs.filter(lang => lang !== "cimode").map((lang) => {
                return (
                    lang == i18n.language ? <span className="active-lang">{lang}</span> :
                        <a href={`/${lang}`} onClick={() => i18n.changeLanguage(lang)}>{lang}</a>)
            })
        }
    </Stack>
}

export {LangSelector}