import {Card, CardGroup, Image} from "react-bootstrap";
import boyarskitImg from "../img/bojarski.jpg";
import boyarskipImg from"../img/pavelb.jpg";
import kelbasImg from "../img/kelbas.jpg";
import {useTranslation} from "react-i18next";

function Team() {
    const {t} = useTranslation();
    return <article>
        <h4>{t("our_team_header")}</h4>
        <CardGroup>
            <Card>
                <Card.Body>
                    <div style={{textAlign: "center"}}>
                        <Image src={boyarskipImg} alt={t("pavel_boyarski")} roundedCircle={true} width={240}/>
                    </div>
                    <Card.Title style={{textAlign: "center", marginTop: "18px"}}>
                        {t("pavel_boyarski")}
                    </Card.Title>
                    <Card.Text style={{textAlign: "center"}}>
                        {t("pavel_position")}
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <div style={{textAlign: "center"}}>
                        <Image src={boyarskitImg} alt={t("taras_boyarski")} roundedCircle={true} width={240}/>
                    </div>
                    <Card.Title style={{textAlign: "center", marginTop: "18px"}}>
                        {t("taras_boyarski")}
                    </Card.Title>
                    <Card.Text style={{textAlign: "center"}}>
                        {t("taras_position")}
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <div style={{textAlign: "center"}}>
                        <Image src={kelbasImg} alt={t("vladimir_kelbas")} roundedCircle={true} width={240}/>
                    </div>
                    <Card.Title style={{textAlign: "center", marginTop: "18px"}}>
                        {t("vladimir_kelbas")}
                    </Card.Title>
                    <Card.Text style={{textAlign: "center"}}>
                        {t("vladimir_position")}
                    </Card.Text>
                </Card.Body>
            </Card>
        </CardGroup>
    </article>
}

export {Team}