import {Col, Container, Row} from "react-bootstrap";
import phone1 from '../img/phone1.png';
import {useTranslation} from "react-i18next";

function Head() {
    const {t} = useTranslation();
    return (
        <div className="fosize-header">
            <Container id="fosize-container">
                <Row>
                    <Col>
                        <h1 id="fosize-brand">FOSIZE</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <h2>{t("head_title")}</h2>
                        <div className="head-text">
                            <p>
                                {t("head_text")}
                            </p>
                            <p>
                                <a href="https://play.google.com/store/apps/details?id=com.fosize.android"><img
                                    width={200} src={t("play_badge")} alt=""/></a>
                            </p>
                        </div>
                    </Col>
                    <Col id="phone-img-head-big" style={{textAlign: "center", paddingTop: "30px"}} sm>
                        <img src={phone1} alt=""/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export {Head}