import '../app.scss';
import {Container, ThemeProvider} from "react-bootstrap";
import {TopMenu} from "./TopMenu";
import {Head} from "./Head";
import {MainBlocksRow} from "./MainBlocksRow";
import {MainContent} from "./MainContent";
import {Footer} from "./Footer";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {defaultLanguage, isSupportedLang} from "./LangRouter";

function App() {
    const {i18n} = useTranslation();
    const {language} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSupportedLang(language)) {
            navigate("/" + defaultLanguage)
        } else {
            if (i18n.language !== language) {
                i18n.changeLanguage(language)
            }
        }
    }, []);

    return (
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        >
            {/*<Container style={{padding: "0px"}}>*/}
            <TopMenu/>
            <Head/>
            <Container id="content">

                <MainBlocksRow/>
                <MainContent/>
            </Container>
            {/*</Container>*/}
            <Footer/>
        </ThemeProvider>
    );
}

export default App;
