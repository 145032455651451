import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";


const supportedLanguages = ["de", "en", "es", "fr", "pl", "ru", "uk", "zh"];
const defaultLanguage = "en";


const isSupportedLang = (lang) => supportedLanguages.indexOf(lang) !== -1;


function LangRouter() {
    const {i18n} = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        let detectedLang = i18n.language
        if (!isSupportedLang(detectedLang)) {
            detectedLang = defaultLanguage;
        }
        navigate('/' + detectedLang)
    })
}

export {LangRouter, isSupportedLang, defaultLanguage, supportedLanguages}